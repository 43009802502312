import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import getUserData from "../../utils/userDataService";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear("token");
    navigate("/login");
    dispatch(setUser(null));
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-menu">
        <h4 className="text-white">Menu</h4>
        <ul>
          <li
            onClick={() => navigate("/user-dashboard")}
            className={`${location.pathname === "/user-dashboard" && "active"}`}
          >
            Dashboard
          </li>
          <li
            onClick={() => navigate("/orders")}
            className={`${location.pathname === "/orders" && "active"}`}
          >
            Orders
          </li>
          <li
            onClick={() => navigate("/wallet")}
            className={`${location.pathname === "/wallet" && "active"}`}
          >
            Wallet
          </li>
          <li
            onClick={() => navigate("/")}
            className={`${location.pathname === "/" && "active"}`}
          >
            Recharge
          </li>
          <li
            onClick={() => navigate("/my-account")}
            className={`${location.pathname === "/my-account" && "active"}`}
          >
            My Account
          </li>
          <li
            className="text-white"
            style={{ cursor: "pointer" }}
            onClick={handleLogout}
          >
            <LogoutIcon className="me-2" />
            Logout
          </li>
        </ul>
      </div>
      <div className="dashboard-content">{children}</div>
    </div>
  );
};

export default DashboardLayout;
