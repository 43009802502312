import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AdminSidemenu from "./AdminSidemenu";
import "./AdminLayout.css";

const AdminHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
    dispatch(setUser(null));
  };
  return (
    <div className="admin-header-main">
      <span>
        Hello! <b>ADMIN</b>
      </span>
      <div className="admin-tools">
        <LogoutIcon onClick={handleLogout} />
        <DragHandleIcon
          onClick={() => setMenu(!menu)}
          className="d-block d-md-block d-lg-none"
        />
        <AdminSidemenu menu={menu} setMenu={setMenu} />
      </div>
    </div>
  );
};

export default AdminHeader;
