const IMAGES = {
  // LOGO
  logo: require("../img/logo.png"),
  cop: require("../img/cop.png"),
  giveaway: require("../img/home/giveaway.png"),
  //HOME
  banner1: require("../img/home/banner1.jpg"),
  banner2: require("../img/home/banner2.jpg"),
  banner3: require("../img/home/banner3.webp"),
  banner4: require("../img/home/banner4.jpeg"),
  banner5: require("../img/home/banner5.jpg"),
  banner6: require("../img/home/banner6.jpg"),
  banner7: require("../img/home/banner7.jpg"),
  safe: require("../img/products/safe.png"),
};

export default IMAGES;
